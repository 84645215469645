@import "custom";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/type";
@import "bootstrap/scss/close";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/card";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/images";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/helpers/visually-hidden";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/utilities/api";
@import "choices.js/public/assets/styles/choices.css";

@include media-breakpoint-up(md) {
  .divide-md-x > * + * {
    border-left: 1px solid white;
  }
  .document-article {
    padding: 1in 1.5in !important;
  }
}

@include media-breakpoint-up(sm) {
  .document-article {
    padding: 0.5in 1in !important;
  }
}

[data-loading] {
    display: none;
}


.dropdown-item-1{
  display: inline-flex;
  align-items: center;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit;
  text-decoration:none;
  background-color: transparent;
  border: 0; 
  @if $dropdown-padding-y == 0 {
    &:first-child {
      @include border-top-radius($dropdown-inner-border-radius);
    }

    &:last-child {
      @include border-bottom-radius($dropdown-inner-border-radius);
    }
  }

  &:hover,
  &:focus {
    color: $dropdown-link-hover-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
    background-image: none;
  }
}

