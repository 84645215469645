@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400 !important;500 !important;600 !important;700&display=swap");

html,
body {
  height: 100%;
}

[x-cloak] {
  display: none !important;
}

/** Variables */

/** Colors */
$primary: #33529d;
$light-gray: #f1f3fc;
$success: #25ad5e;
$light: #f4f4f4;

$front-page-colors: (
        light-green: #d8f8e2,
        light-purple: #f2e1ff,
        light-orange: #ffeede,
        light-blue: #fafbff,
);

/** Font */
$font-family-sans-serif: "Montserrat", sans-serif;

/** Layout */
$enable-cssgrid: true;

/** Navbar, Tabs and Pills **/
$nav-underline-border-width: 0.25rem;
.nav-underline .nav-link.active {
  border-bottom-color: $primary !important;
}

.nav-link:hover {
  border-bottom-color: rgba(black, 0.1);
}

/** Popover */
$popover-max-width: 612px;

/** Configuration */
.asteriskField {
  display: none;
}

@each $name, $color in $front-page-colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

.bg-light {
  background-color: $light;
}

.bg-light-gray {
  background-color: $light-gray;
}

.bg-light-purple {
  background-color: #f2e1ff;
}

.bg-light-orange {
  background-color: #ffeede;
}

.bg-light-blue {
  background-color: #fafbff;
}

.bg-orange {
  background-color: #f37a23;
}

.bg-white-md {
  @media (min-width: 768px) {
    background-color: white !important;
  }
}

/** Font */

$font-family-sans-serif: "Montserrat", sans-serif;

/** Layout */

$enable-cssgrid: true;

/** Icons */

.icon {
  line-height: 1;
  vertical-align: -0.125rem;
}

$icon-sizes: (
        xs: 0.75rem,
        sm: 1rem,
        sm2:1.5rem,
        md: 2rem,
        lg: 3.25rem,
        xl: 4rem,
        xxl: 4.5rem,
);

@each $size-name, $size-value in $icon-sizes {
  .icon-#{$size-name} {
    width: $size-value;
    height: $size-value;
  }
}

/** Logo*/

.logo {
  width: 95px;
  height: 24px;
  vertical-align: middle;
}

/** Components */
.dropdown.dropdown-end .dropdown-menu.show {
  right: 0;
}

#user-dropdown {
  right: 1rem;

  @media(min-width: 1200px) {
    right: 50%;
    transform: translateX(50%);
  }
}


.new__hero {
  background-image: url("../images/background.webp");
}

.hero {
  background-image: url("../images/background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  height: 17rem;
  position: relative;
}

.hero::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: linear-gradient(to right, $primary, transparent);
}

/** Utilities */
.bullet {
  width: 2rem;
  height: 2rem;
}

.divide-md-x > * + * {
  border-left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.inline-radios {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  padding: 0.375rem 1.5rem;
  border-radius: var(--bs-border-radius);
  background-color: #fafbff;
}

.phonenumberfield .choices {
  margin-bottom: 0;
}

.phonenumberfield .choices__list[aria-expanded] {
  width: 21rem;
  z-index: 10;
}

.phonenumberfield .choices__inner {
  border-radius: var(--bs-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

}

.choices {
  border-radius: var(--bs-border-radius);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  display: flex;
  align-items: center;
}

.fieldheight, .choices {
  height: 2.4rem;
}

.wh-auto {
  width: 100% !important;

  @media (min-width: 768px) {
    width: auto !important;
  }
}

.max-w-400 {
  max-width: 400px !important;
}

.max-w-800 {
  max-width: 800px;
}

.max-w-850 {
  max-width: 850px;
}

.max-w-900 {
  max-width: 900px;
}

.max-w-1200 {
  max-width: 1200px !important;
}

.text-justify {
  text-align: justify;
}

.fw-500 {
  font-weight: 500;
}

#id_trial_period_duration_helptext {
  color: #dc3545;
}


details[open] .rotate-icon {
  transform: rotate(180deg);
}

details summary {
  cursor: pointer;
}

details summary::-webkit-details-marker {
  display: none;
}

.rotate-icon {
  transition: transform 0.3s ease;
}

details[open] .rotate-icon {
  transform: rotate(180deg);
}

.user-icon {
  fill: #33529d !important;
}

.answer-text {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

details[open] .answer-text {
  opacity: 1;
  max-height: 1000px;
  height: auto;
}

.service-card {
  position: relative;
}

.responsive-table {
  overflow: auto !important;
}

.service-card-description {
  display: none;
  position: absolute;
  inset: 0;
  opacity: 0;
  user-select: none;
}

.service-card:hover .service-card-description {
  display: block;
  opacity: 1;
  user-select: text;
}

@media screen and (min-width: 768px) {
  .flip-card {
    background-color: transparent !important;
    perspective: 1000px !important;
    height: 350px;
  }

  .flip-card-inner {
    position: relative !important;
    width: 100% !important;
    height: 100% !important;
    transition: transform 0.6s !important;
    transform-style: preserve-3d !important;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg) !important;
  }

  .flip-card-front, .flip-card-back {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    -webkit-backface-visibility: hidden !important;
    backface-visibility: hidden !important;
  }
  .flip-card-back {
    color: white !important;
    transform: rotateY(180deg) !important;
  }
}

@media (max-width: 575px) {
  .flip-card-back {
    transform: rotateY(180deg) !important;
  }
}

.hero-section-image {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: top !important;
  width: 48% !important;
}

.lineClamp3 {
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ded-service-about-container {
  z-index: 10;
}

.ded-service-hero-container {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  height: 100% !important;
  width: 30% !important;
}

.ded-service-hero-container-overlay {
  position: absolute !important;
  inset: 0 !important;
  background-image: linear-gradient(to right, #33529d, transparent) !important;
}

.ded-service-hero-container-img {
  object-fit: cover !important;
  object-position: top !important;
}

.main-menu-button:hover {
  background-color: white !important;
  border-color: #33529d !important;
}

.contact-us-button:hover {
  color: #33529d !important;
  background-color: transparent !important;
  border-color: #33529d !important;
}

.active-link {
  color: #1B1B1B !important;
  background-color: transparent !important;

  &.text-primary {
    color: #212529bf !important;
  }

  &.bg-primary-subtle {
    background-color: inherit !important;
  }

  &.contact-us-button {
    border: none;
  }
}

.text-focus {
  color: #F3D259 !important;
}

.summary {
  padding: 2rem 2rem 0.8rem 0.8rem !important;
  border-radius: 0 8px 8px 0 !important;
  background-color: #fff7e5 !important;
  border-left: 4px solid #ebc348 !important;
}

// MARKDOWN STYLING **start

.prose img {
  width: 100%;
  height: auto;
  margin: 10px auto;
  border-radius: 0.5rem;
}

.prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
  color: #33529d;
  margin-bottom: 20px;
}

.prose h1 {
  font-weight: bold !important;
}

.prose li::marker {
  color: #33529d;
}

// MARKDOWN STYLING **end

.scroll-none::-webkit-scrollbar {
  display: none;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important;
}


// BLOG STYLING
.blog-pagination-container {
  display: flex;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap;
}

.blog-pagination-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  white-space: nowrap;
  max-width: 60%;
  overflow: auto;
}

.blog-pagination-link {
  display: inline-block;
  padding: 8px 16px;
  text-decoration: none;
  text-align: center;
  color: #33529d;
  border: 1px solid #33529d;
  border-radius: 8px !important;
  margin: 0 4px;
}

.blog-pagination-link.active {
  background-color: #33529d;
  color: #fff7e5;
  border-color: #33529d;
}

@media screen and (max-width: 1008px) {
  .flip-card-back {
    font-size: 14px !important;
  }
}


/* CAROUSEL WITH ALPINE */

.carousel {
  overflow-x: scroll !important;
  scrollbar-width: none;
}

.carousel-container {
  position: relative !important;
  overflow-x: scroll !important;

  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge */
  -ms-overflow-style: none;

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;
}

.carousel-inner {
  display: flex !important;
  transition: transform 0.3s ease-in-out !important;
}

.carousel-box {
  flex: 0 0 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.carousel-box b {
  display: inline-block !important;
  height: 96px !important;
  font-size: 16px;
  padding: 24px 0;
}

.carousel--card {
  width: 90% !important;
  margin: 0 auto !important;
  background-color: #33529d10 !important;
}

.carousel-arrow {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background: #33529d10 !important;
  color: #33529d !important;
  border: none !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  border-radius: 50% !important;
}

.carousel-arrow:hover {
  background: rgba(0, 0, 0, 0.7) !important;
}

.arrow-left {
  left: 10px !important;
}

.arrow-right {
  right: 10px !important;
}

/* Responsive Styles */
@media (min-width: 1260px) {
  .carousel-box {
    flex: 0 0 25% !important;
  }
  .arrow-left {
    left: -30px !important;
  }
  .arrow-right {
    right: -30px !important;
  }
  .carousel-arrow {
    display: none !important;
  }
}

@media (min-width: 1032px) and (max-width: 1259px) {
  .carousel-box {
    flex: 0 0 33.33% !important;
  }
  .arrow-left {
    left: -30px !important;
  }
  .arrow-right {
    right: -30px !important;
  }
}

@media (min-width: 768px) and (max-width: 1031px) {
  .carousel-box {
    flex: 0 0 50% !important;
  }

  .carousel-box b {
    height: 80px !important;
    padding: 16px 0;
  }
  .arrow-left {
    left: -30px !important;
  }
  .arrow-right {
    right: -30px !important;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .carousel-box {
    padding: 0 3rem !important;
  }
}

@media (max-width: 575px) {
  .carousel-box {
    flex: 0 0 85% !important;
  }

  .carousel-box b {
    font-size: 14px !important;
  }
}

@media (max-width: 375px) {
  .carousel-box b {
    padding: 16px 0;
  }
}

.legal-notice-header h1 {
  position: absolute !important;
  z-index: 100 !important;
  transform: translate(-50%, -50%) !important;
  top: 50% !important;
  left: 50% !important;
}

.legal-notice-header {
  position: relative;
  height: 130px;
}

.legal-notice-main-content {
  max-width: 1000px !important;
}

.hidden-to-view {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.country-badge {
  position: absolute;
  top: 10px;
  font-size: 14px;
  right: 10px;
}

/* Navbar */

.navbar {
  position: sticky !important;
  top: -5px;
  padding-top: 5px;
  z-index: 100 !important;
}

.navbar-container {
  @extend .container;

  @media (max-width: 575px) {
    justify-content: start !important;
    margin: 0 !important;
    padding-inline: 0 !important;
    width: fit-content !important;

    .navbar-toggler {
      border: none !important;

      :focus {
        box-shadow: none !important;
      }
    }
  }
}

.navlinks-gap {
  gap: 1rem !important;

  @media (min-width: 1200px) {
    gap: 0 1rem !important;
  }
}

.nav-gap {
  gap: 5rem !important;
}

.dropdown-title {
  padding: 0.5rem 1rem !important;
  font-weight: bold !important;
}

.dropdown-link-padding {
  padding: 0.5rem 2rem !important;
}

.offcanvas.show {
  @media (max-width: 1200px) {
    padding: 0 1rem !important;
  }
}

a.dropdown-toggle, a.nav-link {
  @media (max-width: 1200px) {
    font-size: 1.25rem !important;
  }
}

.dropdown-toggle::after {
  @media (max-width: 1200px) {
    position: absolute !important;
    top: 50% !important;
    right: 0 !important;
    transform: translateY(-50%) !important;
  }
}

.research-btn-width {
  @media (max-width: 1200px) {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .flip-card-inner {
    transform: rotateY(180deg) !important;
  }
}

/* bottom sheet */


#services_bottomsheet .modal-dialog {
  position: fixed !important;
  bottom: -1px !important;
  left: 0 !important;
  right: 0 !important;
  margin: 0 !important;
}

.modal.show {
  .modal-dialog {
    transform: translateY(0) !important;
  }
}

.modal.fade:not(.show) {
  .modal-dialog {
    transform: translateY(100%) !important;
  }
}

#services_bottomsheet {
  .modal-content {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: none;
  }
}

@media (max-width: 575px) {
  section#why_us,
  section#our_team,
  section#blog_posts,
  section#our_services {
    background: $light;
    padding-inline: 16px !important;
  }
  #contact_us {
    background: $light;
  }
  #legal-acts-list,
  #accounting-list,
  #company-list {
    background: $light;
  }
}

@media (min-width: 768px) {
  #legal-acts-search-form {
    min-width: 500px;

    .form-control {
      border-radius: 8px 0 0 8px !important;
    }

    .btn {
      border-radius: 0 8px 8px 0 !important;
    }

    &:focus-within {
      .form-control {
        box-shadow: -0.25rem 0 0 .25rem rgba(51, 82, 157, .25) !important;
      }

      .btn {
        box-shadow: 0.25rem 0 0 .25rem rgba(51, 82, 157, .25) !important;
      }
    }
  }
}

/* =================================================================
   COMPANYDECK STYLES
   ================================================================= */

// Company List Page
.companydeck-hero-section {
  background-image: url('../images/companydeck_list_hero.webp');
  background-size: cover;
  background-position: center;
  height: 600px;
  position: relative;
}

.companydeck-search-container {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  padding: 30px;
  max-width: 500px;
}

.companydeck-card {
  margin-bottom: 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid #DAEBFF;
}

.companydeck-card-header {
  background-color: #F2F6FF;
  border-bottom: 1px solid #e9f0ff;
  padding: 1rem;
}

.bold-labels label {
    font-weight: 600;
}

.nowrap {
    white-space: nowrap;
}

.ts-control {
  max-height: 100px;
  overflow: auto
}

/* =================================================================
   COMPANYDECK DETAIL STYLES
   ================================================================= */

.companydeck-detail-hero-section {
  background-image: linear-gradient(#33529DCC, #33529DCC), url('../images/companydeck_detail_hero.webp');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 60px 0;
  margin-bottom: 30px;
}

.back-btn {
  color: #3056a2;
  text-decoration: none;
}

.back-btn i {
  margin-right: 5px;
}

.company-detail-section .row:nth-child(odd) {
    background-color: #F2F6FF;
}

.info-card {
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
}

.info-card p {
  font-weight: 600;
}

.info-card h6 {
  font-weight: 400;
}

.service-card h5 {
  line-height: 1.6;
  font-weight: 600;
}

.service-card {
  border: 1px solid #DAEBFF;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
}

.service-icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.service-icon img {
  width: 50px;
  height: 50px;
}